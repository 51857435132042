const privacyData = `
<div class="container">
      <div class="item-content">
        <h5>Introduction</h5>
        <p>
          Welcome to Haier! www.haier.com.pk is an e-commerce website, and the Privacy Policy outlines the rules and regulations for the use of Haier and its associated websites, sub-domains, mobile app, and services amongst other things. The Privacy Policy hereinafter referred to as the “Privacy Policy” contains types of information that is collected and recorded by Haier and how we use it. By accessing this website and/or mobile app you shall hereby consent to the Privacy Policy and agree to its terms. Do not continue to use Haier if you do not agree to all the policies stated on this page. 

          At Haier, accessible from www.haier.com.pk, One of our main priorities is the privacy of our visitors. If you have additional questions or require more information about our Privacy Policy, contact us at admin@Haier.com.pk 
          
          The following terminology applies to the Privacy Policy, Terms and Conditions and Disclaimer Notice and all Agreements: "Client", "You" and "“Your" refers to you. “We,” “Us,” and “It” refers to Haier. The Privacy Policy applies only to our online activities and visitors to our application and website with regards to the information that they shared and/or collected from Haier. The policy is not applicable to any information collected offline or via channels other than this mobile application and website.
        </p>
      </div>

      <div class="item-content">
        <h5>Applicable Law</h5>
        <p>
          The Terms and Conditions are subject to relevant local laws of the concerned Haier legal entity such as the Electronic Transactions Ordinance,2002 and the Prevention of Electronic Crimes Act,2016.

          In case any of the terms/provisions herein are inconsistent or in contradiction with the applicable laws, the applicable laws shall prevail and the terms/provisions herein shall be interpreted and applied to the maximum extent permissible under the applicable laws.
        </p>
      </div>

      <div class="item-content">
        <h5>Information We Collect</h5>
        <p>
          Data Protection is a matter of trust, and your privacy is important to us. We may therefore only use your name and other information which relates to you in the manner set out in the Privacy Policy. We may only collect information where it is necessary for us to do so, and we may only collect information if it is relevant to our dealings with you. 
          
          We may collect various pieces of information if you seek to place an order for a product with us on the mobile application and website. We collect, store, and process your data for processing your purchase on the site and any possible later claims. We may collect personal information including, but not limited to your title, name, gender, date of birth, email address, postal address, delivery address (if different), telephone number, mobile number, payment details, payment card details or bank account details with your consent. 
          
          When you need to take a photo or select a picture in the album to upload as your profile picture or when you use some other related business functions, we need to obtain your device camera or album permission and collect the picture/video information and related metadata provided by you Data (e.g., who sent the picture, when it was sent, IP address). If you refuse to provide such device permissions, it will only make you unable to use such business functions, but it will not affect your normal use of other functions of the product and/or service. 

          We will collect the Contact List information for recharging, establishing, and managing contact relationships, etc. The above information you provide will continue to authorize us to use it during your use of the service. When you stop using the service, we will stop using and delete the above information. 

          The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information. 

          If you contact us directly, we may receive additional information about you such as your name, email address, phone number, and the content of the message and/or attachments you may send us, and any other information you may choose to provide upon your consent.
          
          When you register for an account, we may ask for your contact information including, but not limited to, items such as name, company name, address, email address, and telephone number.
          
          We may collect information about you in a variety of ways. The information we may collect on the Site includes:

          Personal Data

          Personally identifiable information, such as your name, shipping address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily give to us when you register with the Site or our mobile application, or when you choose to participate in various activities related to the Site and our mobile application, such as online chat and message boards. You are under no obligation to provide us with personal information of any kind, however your refusal to do so may prevent you from using certain features of the Site and our mobile application.
          
          Derivative Data
          
          Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site. If you are using our mobile application, this information may also include your device name and type, your operating system, your phone number, your country, your likes and replies to a post, and other interactions with the application and other users via server log files, as well as any other information you choose to provide.
          
          Financial Data
          
          Financial information, such as data related to your payment method (e.g. valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Site or our mobile application. We store only very limited, if any, financial information that we collect. Otherwise, all financial information is stored by our payment processor and you are encouraged to review their privacy policy and contact them directly for responses to your questions.
          
          Mobile Device Data
          
          Device information, such as your mobile device ID, model, and manufacturer, and information about the location of your device, if you access the Site from a mobile device.
          
          Third-Party Data
          
          Information from third parties, such as personal information or network friends, if you connect your account to the third party and grant the Site permission to access this information.
            - Data From Contests, Giveaways, and Surveys
            - Personal and other information you may provide when entering contests or giveaways and/or responding to surveys.
        </p>
      </div>

      <div class="item-content">
        <h5>How We Use Your Information</h5>
        <p>
          We may only keep your information for as long as we are either required to by law or as is relevant for the purposes for which it was collected. By providing us with your contact information including, but not limited to, items such as name, company name, address, email address, and telephone number you shall be agreeing to our Privacy Policy.
          
          We may cease to retain your personal information or remove the means by which the data can be associated with you, as soon as it is reasonable to assume that such retention no longer serves the purpose for which the personal data was collected, and is no longer necessary for any legal or business purpose.
          
          You can visit the mobile application and website and browse without having to provide personal details. During your visit to the site you remain anonymous and at no time can we identify you unless you have an account on the mobile application and website and log on with your username and password.

          We use the information we collect in various ways, including but not limited to:

            - Provide, operate, and maintain our mobile application and website,
            - Improve, personalise, and expand our mobile application and website,
            - Understand and analyse how you use our mobile application and website, 
            - Develop new products, services, features, and functionality,
            - Communicate with you, either directly or through one of our partners including for customer service to provide you with updates and other information relating to the mobile application and website, and for marketing and promotional purposes. 
        </p>
      </div>


      <div class="item-content">
        <h5>Marketing Emails</h5>
        <p>
          We may send you marketing emails which you can unsubscribe from by clicking the link at the bottom of the email. We may share your contact information with our marketing providers subject to change, so they can send these emails on our behalf. 
          
          We may email you with new offers, promotional offers, new updates and messages about your order or account activity. For example, amongst other things we may email you to inform that:
          
            - You have created a customer account,
            - Your customer account password has been reset or updated,
            - You have made a purchase,
            - Your order has shipped,
            - It is not possible to unsubscribe from these messages.
        </p>
      </div>


      <div class="item-content">
        <h5>Log Files</h5>
        <p>
          Haier follows a standard procedure of using log files. These files log visitors when they visit mobile applications and websites. All hosting companies do this and are a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks amongst other things. These are not linked to any information that is personally identifiable. The purpose of the information is for analysing trends, administering the site, tracking users’ movement on the website, and gathering demographic information amongst other things.
        </p>
      </div>

      <div class="item-content">
        <h5>Cookies and Web Beacons</h5>
        <p>
          Like any other website, Haier uses “cookies.” By accepting the use of cookies, you shall be agreeing to let the company store information including, but not limited to, visitors' preferences, and the pages on the website that the visitor accessed or visited. The information may be used to optimise the users' experience by customising our web page content based on visitors' browser type and/or other information amongst other things.
        </p>
      </div>

      <div class="item-content">
        <h5>Advertising Partners Privacy Policies</h5>
        <p>
          We advise you to refer to the respective Privacy Policies for each of the advertising partners of Haier for more detailed information. It may include their instructions on how to opt out of certain types of data usage.

          Third-party ad servers or ad networks use technologies like cookies, JavaScript, or web beacons that are used in their respective advertisements and links that appear on Haier, which are sent directly to users' browsers. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalise the advertising content that you see on websites that you visit.
          
          Note that Haier has no access to or control over cookies that are used by third-party advertisers. Haier clearly disclaims any liability for loss or damage to you as a result of the accessing and use of third-party sites.
        </p>
      </div>

      <div class="item-content">
        <h5>Third-Party Privacy Policies</h5>
        <p>
          The Privacy Policy does not apply to other advertisers or websites. Thus, we advise you to refer to the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their instructions on how to opt out of certain types of data usage.
          
          You can choose to disable cookies through your individual browser options. To know more about cookie management for specific web browsers, refer to the browsers' respective websites.
        </p>
      </div>

      <div class="item-content">
        <h5>General Data Protection Rights</h5>
        <p>
          We would like to make sure you are fully aware of all your data protection rights. Every user is entitled to the following:

          <span class='subheading'>The right to access</span> – You have the right to request copies of your personal data. We may charge you a small fee for this service.

          <span class='subheading'>The right to rectification</span> – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.

          <span class='subheading'>The right to erasure</span> – You have the right to request that we erase your personal data, under certain conditions.

          <span class='subheading'>The right to restrict processing</span> – You have the right to request that we restrict the processing of your personal data, under certain conditions.

          <span class='subheading'>The right to object to processing</span> – You have the right to object to our processing of your personal data, under certain conditions.

          <span class='subheading'>The right to data portability</span> – You have the right to request that we transfer the data that we have collected to another organisation, or directly to you, under certain conditions.

          If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
        </p>
      </div>

      <div class="item-content">
        <h5>Children's Information</h5>
        <p>
          Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.

          Haier does not knowingly collect any personal identifiable information from children under the age of 13. If you think that your child provided this kind of information on our mobile application and website, we strongly encourage you to contact us immediately and we will do our best to promptly remove such information from our records.
        </p>
      </div>

      <div class="item-content">
        <h5>Service Providers</h5>
        <p>
          We may employ third-party companies and individuals due to the following reasons amongst others:

            - To facilitate our service
            - To Provide the service on our behalf
            - To perform service-related services or
            - To Assist us in analysing how our service is used.
          
          We want to inform users of this service that these third parties have access to your personal information. The purpose is to perform the tasks assigned to them on our behalf. However, they are obligated to not disclose or use the information for any other purposes. 
        </p>
      </div>

      <div class="item-content">
        <h5>Security</h5>
        <p>
          We value your trust in providing us with your personal information. Thus, we are striving to use commercially acceptable means (Periodic Data Backup and Encryption) to prevent loss and theft. It is to be kept in mind that no method of transmission over the internet or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
        </p>
      </div>

      <div class="item-content">
        <h5>Changes to the Privacy Policy</h5>
        <p>
          We may update the Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. These changes shall be effective immediately after they are posted on this page.
        </p>
      </div>

      <div class="item-content">
        <h5>Complaints</h5>
        <p>
          If you have any concerns or complaints about how we access or use your information, please contact us at <a href="mailto:support@haier.com.pk">support@haier.com.pk</a>, so that we can, where possible, resolve the issue. 
        </p>
      </div>
    </div>
    <style>
      .container {
        max-width: 1320px;
        padding: 0 20px;
        margin: 30px auto;
        font-size: 16px;
        word-break: break-all;
      }
      .container h5 {
        font-size: 1.3em !important;
        font-weight: bold !important;
        margin: 0 0 0rem 0;
      }
      .container p {
        line-height: 1.5; /* 行高 */
        color: #333; /* 文本颜色 */
        margin-bottom: 10px; /* 段落间距 */
        white-space: pre-wrap;
        word-break: break-word;
      }
      .container p .subheading {
        font-weight: 600;
      }
      .container a {
        color: -webkit-link;
      }
    </style>
`

export default privacyData

const conditionsData = `
<div class="container">
    <div class="item-content">
    <h5>Introduction</h5>
    <p>
        Welcome to Haier! www.haier.com.pk is an e-commerce website and these Terms and Conditions outline the rules and regulations for the use of Haier and its associated websites, sub-domains, mobile app and services. By accessing this website and/or mobile app you shall accept these Terms and Conditions. Do not continue to use Haier if you do not agree to all the Terms and Conditions stated on this page.
        
        The following terminology applies to these Terms and Conditions, <a href="">Privacy Statement</a>, Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person logged on this website and/or mobile app and compliant to the Company’s Terms and Conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing law of Pakistan. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to the same.
    </p>
    </div>

    <div class="item-content">
    <h5>Applicable Law</h5>
    <p>
        The Policy is subject to the relevant local laws of the concerned Haier legal entity such as the Electronic Transactions Ordinance, 2002 and the Prevention of Electronic Crimes Act, 2016.

        In case any of the terms/provisions herein are inconsistent or in contradiction with the applicable laws, the applicable laws shall prevail and the terms/provisions herein shall be interpreted and apply to the maximum extent permissible under the applicable laws.
    </p>
    </div>


    <div class="item-content">
    <h5>Changes to Terms and Conditions</h5>
    <p>
        The Company reserves the right to edit, change, make additions to, or delete any part of the Terms and Conditions without prior notice. We may update our Terms and Conditions from time to time. Thus, you are advised to review this page periodically for any changes, we will notify you of any changes by posting the new Terms and Conditions on this page. These changes are effective immediately after they are posted on this page.
    </p>
    </div>

    <div class="item-content">
    <h5>Cookies</h5>
    <p>
        We employ the use of cookies. By accessing Haier, you agreed to use cookies in agreement with the <a href="">Haier Privacy Policy</a>.
        
        Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.
    </p>
    </div>

    <div class="item-content">
    <h5>License</h5>
    <p>
        Unless otherwise stated, Haier and/or its licensors own the intellectual property rights for all material on Haier. All intellectual property rights are reserved. You may access this from Haier for your own personal use subject to restrictions set in these Terms and Conditions.
        
        You must not:
        
        - Republish material from Haier
        - Sub-licence material from Haier
        - Reproduce, duplicate or copy material from Haier
        - Redistribute content from Haier
        
        Haier reserves the right, at its sole discretion, to change, modify	, add, or remove portions of these Terms and Conditions, at any time. It is your responsibility to check these Terms and Conditions periodically for changes. Your continued use of our website following the posting of changes will mean that you accept and agree to the changes. 
    </p>
    </div>


    <div class="item-content">
    <h5>User Generated Content</h5>
    <p>
        This Agreement shall begin on the date hereof. Parts of this website and/or mobile app offer an opportunity for users to post and exchange opinions and information in certain areas of the website and mobile app. Comments do not reflect the views and opinions of Haier, its agents and/or affiliates. Comments reflect the views and opinions of the person who posts their views and opinions. To the extent permitted by applicable laws, Haier shall not be liable for the comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the comments on this website and/or mobile app.
        
        Haier reserves the right to monitor all comments and to remove any comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.
        
        You warrant and represent that:
        
        - You are entitled to post the comments on our website and/or mobile app and have all necessary licences and permissions to do so
        - The comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party
        - The comments do not contain any defamatory, libellous, offensive, indecent, confidential or otherwise unlawful material which is an invasion of privacy
        - The comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity
        - The comments and their content will not be used to harass, spam, or stalk any person or other entities or to request any personal information
        - The comments and their content will not be used for hacking. Introducing viruses, or tampering with our website
        
        You hereby grant Haier a perpetual, irrevocable, royalty-free, transferrable right and license to use, modify, reproduce, transmit, publish, display, delete, and distribute any information or materials you share on our website.  
    </p>
    </div>

    <div class="item-content">
    <h5>Hyperlinking to our Content</h5>
    <p>
        The following organisations may link to our website and/or mobile app without prior written approval:
        
        - Government Agencies
        - Search Engines
        - News Organisations
        - Online Directory Distributors may link to our website and/or mobile app in the same manner as they hyperlink to the websites of other listed businesses
        - System Wide Accredited Businesses except soliciting non-profit organisations, charity shopping malls, and charity fundraising groups which may not hyperlink to our website and/or mobile app.
        
        These organisations may link to our home page, to publications or to other website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.
        
        We may consider and approve other link requests from the following types of organisations:

        - Commonly-known consumer and/or business information sources
        - Dot.com community sites
        - Associations or other groups representing charities
        - Online Directory Distributors
        - Internet Portals
        - Accounting, Law and Consulting Firms
        - Educational Institutions and Trade Associations

        We will approve link requests from these organisations if we decide that: (a) the link would not make us look unfavourable to ourselves or to our accredited businesses; (b) the organisation does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Haier; and (d) the link is in the context of general resource information.
        
        These organisations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.
        
        If you are one of the organisations listed above and are interested in linking to our website and/or mobile app, you must inform us by sending an email at support@Haier.com.pk. Please include your name, your organisation name, contact information, the URL of your site, a list of any URLs from which you intend to link to our website and/or mobile app, and a list of the URLs on our site to which you would like to link. It is requested that you wait 2-3 weeks for a response.
        
        Approved organisations may hyperlink to our website and/or mobile app as follows:

        - By use of our corporate name or
        - By use of the Uniform Resource Locator (URL) being linked to or
        - By using any other description of our website and/or mobile app being linked to that makes sense within the context and format of content on the linking party’s site
        
        No use of Haier's logo or other artwork will be allowed for linking absent a trademark licence agreement. Logo, patents, graphics, user interface, audio clips, video clips, domain names, and editorial content contain proprietary information that is owned by Haier. 
    </p>
    </div>

    <div class="item-content">
    <h5>Content Liability</h5>
    <p>
        Under no circumstances shall Haier or its employees or directors be liable for any direct or indirect losses or damages arising out of or in connection with your use of or inability to use our website. This is a comprehensive limitation of liability that applies to all losses and damage of any kind (whether general, special, consequential, incidental, exemplary, or otherwise, including without limitation loss of data, income, or profits). 
    </p>
    </div>

    <div class="item-content">
    <h5>Your Privacy</h5>
    <p>
        Please read the <a href="">Privacy Policy</a>
    </p>
    </div>

    <div class="item-content">
    <h5>Reservation of Rights</h5>
    <p>
        We reserve the right to request that you remove all links or any particular link to our website and/or mobile app. You approve to immediately remove all links to our website and/or mobile app upon request. We also reserve the right to amend these Terms and Conditions and its associated policy at any time. By continuously linking to our website, you agree to be bound to and follow these associated Terms and Conditions.
        
        We (Haier) reserve the right to cancel any order without any user’s consent at any time.
    </p>
    </div>

    <div class="item-content">
    <h5>Removal of links from our website</h5>
    <p>
        If you find any link on our website and/or mobile app that is offensive for any reason, you are free to contact and inform us at any moment. We will consider requests to remove links but we are not obligated to do so or to respond to you directly.

        We do not ensure that the information on this website and/or mobile app is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website and/or mobile app remains available or that the material on the website and/or mobile app is kept up to date.
    </p>
    </div>

    <div class="item-content">
    <h5>Disclaimer</h5>
    <p>
        To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to and the use of this website and/or mobile app. Nothing in this disclaimer will:
        
        - Limit or exclude our or your liability for death or personal injury
        - Limit or exclude our or your liability for fraud or fraudulent misrepresentation
        - Limit any of our or your liabilities in any way that is not permitted under applicable law or
        - Exclude any of our or your liabilities that may not be excluded under applicable law
        
        The limitations and prohibitions of liability set in this section and elsewhere in this disclaimer:

        (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.
        
        As long as the website and/or mobile app, and the information and services on the website and/or mobile app are provided free of charge, we will not be liable for any loss or damage of any nature.
    </p>
    </div>

    <div class="item-content">
    <h5>Use of Services</h5>
    <p>
        You agree to use our website for lawful purposes only and in a way that does not infringe the rights or restrict or inhibit any person’s use of our website, product, or service and in compliance at all times with these Terms and conditions and with all laws and regulations that apply.
        
        In accessing our website, you agree not to adapt, alter, or create a derivative form any content on our website. You will need our prior written permission if you want to use any content on our website for any reason.
        
        Haier reserves the right to suspend any account or remove any user content which may be found in violation of these Terms and Conditions or otherwise without any prior notice to the user. 
    </p>
    </div>

    <div class="item-content">
    <h5>Information on our Site</h5>
    <p>
        Prices and promotions are subject to change and may vary from those offered in our other online or offline store. The price or availability of any item is only confirmed when your order is placed. 
    </p>
    </div>

</div>
<style>
.container {
max-width: 1320px;
padding: 0 20px;
margin: 30px auto;
font-size: 16px;
word-break: break-all;
}
.container h5 {
font-size: 1.3em !important;
font-weight: bold !important;
margin: 0 0 0rem 0;
}
.container p {
line-height: 1.5; /* 行高 */
color: #333; /* 文本颜色 */
margin-bottom: 10px; /* 段落间距 */
white-space: pre-wrap;
word-break: break-word;
}
.container p .subheading {
font-weight: 600;
}
.container a {
color: -webkit-link;
}
</style>
`


export default conditionsData
<template>
  <div>
    <div v-if="data.userPrivacy" v-html="data.userPrivacy"></div>
  </div>
</template>

<script setup>
  import privacyData from './compontent/privacy'
  import conditionsData from './compontent/conditions'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  const { proxy } = getCurrentInstance()

  const data = reactive({
    type: '',
    userPrivacy: ''
  })

  const getUserPrivacy = () => {
    proxy.$api
      .get('services/common/api/system-configs', {
        'key.equals': data.type
      })
      .then((res) => {
        console.log(res, 'res')
        data.userPrivacy = res[0]
      })
      .catch((err) => {})
  }

  const getUserPrivacy2 = () => {
    console.log(2222)
    data.userPrivacy = data.type === 'USER_PRIVACY' ? privacyData : conditionsData
  }

  onMounted(() => {
    data.type = useRoute().query && useRoute().query.type === 'TERMS_CONDITIONS' ? 'TERMS_CONDITIONS' : 'USER_PRIVACY'
    getUserPrivacy2()
  })
</script>

<style scoped></style>
